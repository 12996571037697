<template>
	<div class="saved-properties">
		<div class="_saved-properties__heading">
			<div class="_saved-properties__send">
				<h2>Send by e-mail</h2>
				<input type="mail" v-on:keyup.enter="send" v-model="email" placeholder="Destination e-mail">
				<div class="_saved-properties__send--greeting">
					<input type="text" v-model="greeting" placeholder="Add message" rows="1"></input>
				</div>
				<button @click.prevent="send">Send by email</button>
				<p :class="{'_saved-properties__message--success': !error, '_saved-properties__message--error': error }"
					class="_saved-properties__message">
					{{ message }}
				</p>
			</div>
			
			<a @click.prevent="removeAll" class="_saved-properties__delete">Delete all</a>
			
		</div>
		<div class="saved-properties__columns">
			<div class="saved-properties__left">
				<div v-if="deleted === false" class="saved-properties__container">
					<SavedProperty
						v-for="property of properties"
						:key="property.id"
						:property="property"
						v-if="isDoneLoadingImages"
					/>
				</div>
			</div>
			<div class="saved-properties__right">
				<Map :visible-properties="properties"
					:properties="properties"
					:deletedProperties="deletedProperties"
					:zoom="zoom"
					:center="center"
					:hovered="hovered"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import SavedProperty from './SavedProperty.vue'
import Map from './Map.vue'
import axios from 'axios'


export default {
	name: 'SavedProperties',

	components: {
		SavedProperty,
		Map
	},

	data() {
		return {
			properties: [],
			deletedProperties: [],
			center: {
				lat: 38.72,
				lng: -9.16
			},
			zoom: 12,
			hovered: null,
			deleted: false,
			email: null,
			message: null,
			error: false,
			greeting: '',
			isDoneLoadingImages: false
		}
	},

	props: {
		gmap: {
			type: String,
			default: null
		},
		pluginPath: {
			type: String,
			required: true
		}
	},

	mounted() {
		let params = this.getUrlParameters()
		if (params['hash']) {
			this.getPropertiesFromHash(params['hash'])
		}
		else {
			this.isDoneLoadingImages = true;
			this.getProperties()
		}
	},

	methods: {
		getUrlParameters() {
			return window.location.search.slice(1).split('&').reduce((a,b) => {
				b = b.split('=')
				a[b[0]] = decodeURIComponent(b[1])
				return a
			}, [])
		},
		getPropertiesFromHash(hash) {
			// let decoded = window.rentalsUnited.decodeLikedProperties(hash)
			// this.properties = decoded === null ? this.getProperties() : decoded

			let decoded = window.rentalsUnited.decodeLikedProperties(hash)
			if (decoded === null) {
				this.properties = this.getProperties();
			}
			else {
				this.properties = decoded;
				let ids = decoded.map(d => d.ID)
				let formData = new FormData
				formData.append('action', 'get_hash_images')
				formData.append('nonce', window.ru.nonce)
				formData.append('ids', ids)
				let that = this;
				axios.post(window.ru.ajax_url, formData).then(res => {
					if (res.status === 200) {
						that.properties.forEach(function(property) {
							property.images = [];
							for (var i=0; i < res.data.length; i++) {
								if (property.ID == res.data[i].parentID) {
									property.images.push(res.data[i].url)
								}
							}
						})
						that.isDoneLoadingImages = true;
					}
					else {
						console.error('Error getting slider images')
					}
				})
			}
		},
		getProperties() {
			this.properties = window.rentalsUnited.getSavedProperties()
		},
		removeAll() {
			window.rentalsUnited.removeAllSavedProperties()
			this.deletedProperties = this.properties.map(e => e.ID)
			this.deleted = true
		},
		send() {
			if (this.validateEmail(this.email) === false) {
				this.message = 'Invalid e-mail'
				this.error = true
				return false
			}
			this.message = null
			let formData = new FormData
			formData.append('action', 'send_by_mail')
			formData.append('nonce', window.ru.nonce)
			formData.append('greeting', this.greeting)
			formData.append('email', this.email)
			formData.append('path', window.location.pathname)
			formData.append('hash', window.rentalsUnited.encodeLikedProperties())
			formData.append('properties', JSON.stringify(this.properties))
			axios.post(window.ru.ajax_url, formData).then(res => {
				if (res.data.success === true) {
					this.error = false
					this.message = 'Message sent!'
				}
				else {
					this.error = true
					this.message = 'Error sending mail'
				}
			}).catch(() => {
				this.error = true
				this.message = 'Error sending mail'
			})
		},
		validateEmail(email) {
			let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return reg.test(String(email).toLowerCase())
		}
	}
}
</script>

<style>
a {
	cursor: pointer;
}
.saved-properties__columns {
	display: flex;
}
.saved-properties__left {
	flex: 75%;
}
.saved-properties__right {
	flex: 25%;
}
.saved-properties__container {
	display: flex;
	flex-wrap: wrap;
}
.saved-properties__wrapper {
	max-width: 100%!important;
}
.saved-properties__message-error {
	color: red;
}
.saved-properties__message-success {
	color: green;
}
</style>
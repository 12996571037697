<template>
	<div v-if="deleted === false" @mouseover="hovered(property.ID)" @mouseleave="hovered(null)" class="single-property-component" >
		<div @click.prevent="toProperty" class="saved-property__heading">
			<div class="single-property-component__image">
				<Slider v-if="imagesForSlider.length > 1" :images="imagesForSlider" />
				<img v-else :src="property.image">
			</div>
			<div class="single-property-component__title">
				<h3>{{ property.post_title }}</h3>

			</div>
				<div class="single-property-component__content">
					<p>{{ property.rooms | plural('Room') }} | {{ property.guests | plural('Guest') }} |  From {{ property.price }}€ </p>
					<!-- <span @click.prevent.stop="toggleLike" :class="{ 'single-property-component__heart-liked': liked }" class="single-property-component__heart">
						
					</span> -->
				</div>
		</div>
		<div @click.prevent="toProperty" class="saved-property__content">
			<span v-if="property.from">
				From <b>{{ property.from }}</b>
			</span>
			<span v-if="property.to">
				To <b>{{ property.to }}</b>
			</span>
		</div>
		<div class="saved-property__actions">
			<div class="saved-property__actions--delete" @click.prevent="remove">delete</div>
		</div>
	</div>
</template>



<script>
import Slider from './Slider.vue'

export default {
	name: 'SavedProperty',
	components: {
		Slider
	},

	props: {
		property: {
			type: Object,
			default: () => {},
			required: true
		}
	},

	data() {
		return {
			deleted: false,
		}	
	},

	filters: {
		plural(value, text) {
			return value > 1 ? value + ' '+text+'s' : value + ' '+text
		}
	},

	mounted() {
	},

	computed: {
		imagesForSlider() {
			let selected = this.property.images;
			let thumbnail = [this.property.image, ...selected];
			return thumbnail.filter((value, key) => thumbnail.indexOf(value)===key);
		}
	},

	methods: {
		remove() {
			window.rentalsUnited.removeFromSavedProperties(this.property.ID)
			this.$parent.deletedProperties.push(this.property.ID)
			this.deleted = true
		},
		hovered(id) {
			this.$parent.hovered = id
		},
		toProperty() {
			let url = this.property.link
			let params = {}
			if (this.property.guests > 0) {
				params.guests = this.property.guests
			}
			if (this.property.rooms > 0) {
				params.rooms = this.property.rooms
			}
			if (this.property.from && this.property.to && this.property.from != '0' && this.property.to != '0') {
				params.from = this.property.from
				params.to = this.property.to
			}
			if (Object.keys(params).length) {
				url += '?' + Object.keys(params).map(k => k + '=' + params[k]).join('&')
			}
			window.location = url
		},
	}
}
</script>

<style>
.saved-property {
	flex: 0 0 15em;
	color: #000;
	margin: 10px;
	border-radius: 5px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	padding: 16px;
}
.saved-property:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	transform: scale(1.1);
}
.saved-property__actions {
	display: flex;
	justify-content: space-between;
}
.saved-property__actions a {
	cursor: pointer;
	font-size: 16px;
	z-index:99999999 !important;
}
.saved-property__content span {
	font-size: 16px;
}
.saved-property__image img {
	width: 100%;
}
</style>


<template>
	<div class="rentals-united-map">
		<div id="map"></div>
	</div>
</template>

<script>

export default {
	name: 'Map',

	watch: {
		properties() {
			this.setMarkers()
		},
		visibleProperties() {
			this.setMarkerVisibility()
		},
		deletedProperties() {
			this.deleteMarkers()
		},
		zoom() {
			this.updateMapPosition()
		},
		center: {
			handler() {
				this.updateMapPosition()
			},
			deep: true
		},
		hovered(newValue, oldValue) {
			this.setHoveredMarker(newValue, oldValue)
		}
	},

	props: {
		properties: {
			type: Array,
			default: () => []
		},
		visibleProperties: {
			type: Array,
			default: () => []
		},
		hovered: {
			type: Number,
			default: null
		},
		center: {
			type: Object,
			default: () => ({
				lat: 38.72,
				lng: -9.16
			})
		},
		zoom: {
			type: Number,
			default: 12
		},
		deletedProperties: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			map: null,
			markers: [],
			infoWindow: null,
			isUserInteractingWithTheMap: false,
			icon: null,
			iconBig: null
		}
	},

	computed: {
		apiKey() {
			return this.$parent.gmap
		},
		path() {
			return this.$parent.pluginPath + '/public/'
		}
	},

	mounted() {
		const script = document.createElement('script');
		script.async = true;
		script.defer = true;
		script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&callback=gmapCallback';
		document.querySelector('head').appendChild(script);

		window.gmapCallback = () => this.initMap();
	},

	methods: {
		initMap() {
			this.map = new window.google.maps.Map(document.getElementById('map'), {
				center: this.center,
				zoom: this.zoom,
				disableDefaultUI: true,
				zoomControl: true
			})
			this.map.addListener('bounds_changed', () => {
				let bounds = this.getMapBounds()
				this.$emit('map-bounds-changed', bounds)
				if (this.isUserInteractingWithTheMap === true) {
					this.$emit('user-map-interaction')
				}
			})
			this.map.addListener('mousemove', () => {
				this.isUserInteractingWithTheMap = true
			})
			this.infoWindow = new window.google.maps.InfoWindow({
				disableAutoPan: true
			})
			this.icon = this.path + 'Map_Place_Small.svg'
			this.iconBig = this.path + 'Map_Place_Big.svg'
		},

		setMarkers() {
			setTimeout(() => {
				for (let p in this.properties) {
					this.createMarker(this.properties[p])
				}
			}, 1000)
		},

		createMarker(property) {
			this.markers[property.ID] = new window.google.maps.Marker({
				position: {
					lat: Number(property.coordinates.Latitude),
					lng: Number(property.coordinates.Longitude)
				},
				title: property.post_title,
				map: this.map,
				url: property.link,
				icon: this.icon,
			})
			this.markers[property.ID].addListener('mouseover', () => {
				this.infoWindow.setOptions({
					content: '<div id="content"><h3>' + property.post_title + '<img style="width: 100%;" src="' + property.image + '"></h3></div>'
				})
				this.infoWindow.open(this.map, this.markers[property.ID])
			})
			this.markers[property.ID].addListener('mouseout', () => {
				this.infoWindow.close()
			})
			this.markers[property.ID].addListener('click', () => {
				window.location.href = this.markers[property.ID].url
			})
		},

		setMarkerVisibility() {
			for (let k in this.properties) {
				if (this.visibleProperties.includes(this.properties[k].ID)) {
					this.showMarker(this.properties[k])
				}
				else {
					this.hideMarker(this.properties[k])
				}
			}
		},

		hideMarker(property) {
			if (this.markers[property.ID]) this.markers[property.ID].setVisible(false)
		},

		showMarker(property) {
			if (this.markers[property.ID]) this.markers[property.ID].setVisible(true)
		},

		deleteMarkers() {
			this.deletedProperties.forEach(element => {
				this.markers[element].setMap(null)
			});
		},

		getMapBounds() {
			let bounds = this.map.getBounds()
			let NorthEast = bounds.getNorthEast()
			let SouthWest = bounds.getSouthWest()
			return {
				NE: { lat: NorthEast.lat(), lng: NorthEast.lng() },
				SW: { lat: SouthWest.lat(), lng: SouthWest.lng() },
			}
		},

		updateMapPosition() {
			if (this.map !== null) {
				this.isUserInteractingWithTheMap = false
				this.map.setCenter(this.center)
				this.map.setZoom(this.zoom)
			}
		},

		setHoveredMarker(newMarker, oldMarker) {
			if (this.markers[newMarker] !== undefined && oldMarker === null) {
				this.markers[newMarker].setIcon(this.iconBig)
			}
			else if (this.markers[oldMarker] !== undefined && newMarker === null) {
				this.markers[oldMarker].setIcon(this.icon)
			}
		}
	}
}

</script>

<style>
#map {
	position: fixed;
	height: 400px;
}
</style>
<template>
	<div class="slider">
		<div class="slider__image" >
			<img :src="currentImage">
			<div class="slider__arrows">
				<div @click.stop.prevent="minusSlides" />
				<div @click.stop.prevent="plusSlides" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Slider',

	props: {
		images: {
			type: Array,
			default: () => []
		},
	},

	data() {
		return {
			slideIndex: 0,
		}
	},

	computed: {
		currentImage() {
			return this.images[this.slideIndex];
		}
	},

	methods: {
		plusSlides() {
			this.slideIndex++
			if (this.slideIndex == this.images.length) {
				this.slideIndex = 0
			}
		},

		minusSlides() {
			this.slideIndex--
			if (this.slideIndex < 0) {
				this.slideIndex = this.images.length - 1;
			}
		}
	}
}
</script>


<style>

.slider__image {
	height: 200px;
	max-width: 100%;
	background-size: cover;
}

.slider__arrows {
	display:flex;
	justify-content: space-between;
	z-index:9999999;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;

	div {
		background:red;
	}
}

</style>